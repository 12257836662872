import styled from "astroturf"
import React, { useState, useRef } from "react"
import { messageTypes } from "../../constants/messageTypes"
import { BigWhiteButton } from "../../ui/Buttons"
import { ChipSelectGroup } from "../../ui/ChipSelectGroup"
import { Input } from "../../ui/Input"
import attach from "../../images/attach.svg"
import { dataURItoBLOB } from "../../services/dataURItoBLOB"
import ym from "react-yandex-metrika"
import { getCookie } from "../../constants/getCookie"
import { InputArea } from "../../ui/InputArea"

export const WriteToUsForm = ({ elId }) => {
  const [selected, setSelected] = useState(messageTypes)

  const [name, setName] = useState("")
  const [nameError, setNameError] = useState(false)

  const [contact, setContact] = useState("")
  const [contactError, setContactError] = useState(false)

  const [message, setMessage] = useState("")

  const [successMessage, setSuccessMessage] = useState()
  const [failMessage, setFailMessage] = useState()

  const [file, setFile] = useState()
  const [fileName, setFileName] = useState()

  const [loading, setLoading] = useState(false)

  const onSelectType = typeKey => {
    setSelected({
      ...selected,
      [typeKey]: { ...selected[typeKey], active: !selected[typeKey].active },
    })
  }

  const onSubmit = async () => {
    ym("reachGoal", "footerSendMessage")
    const nameCurrentError = name.trim() === ""
    setNameError(nameCurrentError)

    const contactCurrentError = contact.trim() === ""
    setContactError(contactCurrentError)

    if (!nameCurrentError && !contactCurrentError) {
      const selectedTags = Object.keys(selected)
        .map(key => selected[key])
        .filter(({ active }) => active)
        .map(({ label }) => label)

      try {
        setLoading(true)
        const body = new FormData()
        body.append("name", name)
        body.append("phone", contact)
        body.append("message", `${selectedTags.length > 0 ? `${selectedTags.join(", ")}. ` : ""}${message}`)
        body.append("UTM", getCookie("UTM"))
        body.append("URL", getCookie("URL"))
        body.append("YandexClientID", getCookie("clientID"))

        if (file) {
          body.append("file", dataURItoBLOB(file), fileName)
        }
        await fetch("https://dex-it.ru/Ru/Page/TechTaskEmail", {
          method: "POST",
          headers: {
            // "Content-Type": "multipart/form-data",
          },
          body,
        })
        setSuccessMessage("Thank you for appeal! We will contact you within 45 minutes!")
        setFailMessage()

        setLoading(false)

        setName("")
        setContact("")
        setMessage("")
        setSelected(messageTypes)
        setFile()
        setFileName()
      } catch (error) {
        setFailMessage(error.message)
        setSuccessMessage()
      }
    }
  }

  const onChangeField = name => e => {
    switch (name) {
      case "name": {
        setName(e.target.value)
        return
      }
      case "contact": {
        setContact(e.target.value)
        return
      }
      case "message": {
        setMessage(e.target.value)
        return
      }
      default: {
        return
      }
    }
  }

  const fileInputRef = useRef(null)

  const onClickAttach = () => {
    if (fileInputRef.current != null) {
      fileInputRef.current.value = ""
      fileInputRef.current.click()
    }
  }

  const onFileInputChange = async () => {
    if (fileInputRef.current != null) {
      const files = fileInputRef.current.files
      if (files && files[0]) {
        setFile(undefined)
        setFileName(undefined)

        const reader = new FileReader()
        // tslint:disable-next-line: no-any
        reader.onload = e => {
          setFile(e.target.result)
          setFileName(files[0].name)
        }
        reader.readAsDataURL(files[0])
      }
    }
  }

  return (
    <Container>
      <FieldsContainer id={elId}>
        <MobileStub />
        <ChipSelectGroup selected={selected} onSelect={onSelectType} />
        <ContactsRow>
          <InputWrapper>
            <Input placeholder="Name" onChange={onChangeField("name")} value={name} />
            <Error active={nameError}>Please enter your name</Error>
          </InputWrapper>
          <InputWrapper>
            <Input placeholder="E-mail or phone" onChange={onChangeField("contact")} value={contact} />
            <Error active={contactError}>Please fill in the field</Error>
          </InputWrapper>
        </ContactsRow>

        <MessageContainer>
          <InputArea placeholder="Message" onChange={onChangeField("message")} value={message} />
          {/* <Input placeholder="Текст сообщения" onChange={onChangeField("message")} value={message} /> */}
          {file && <FileName>{fileName}</FileName>}
          <AttachButton src={attach} onClick={onClickAttach} alt={"AttachButton"} />
          <InvisibleFileInput
            ref={fileInputRef}
            type="file"
            // accept=".jpg,.jpeg,.png"
            onChange={onFileInputChange}
          />
        </MessageContainer>
      </FieldsContainer>

      {loading ? <SendButton disabled={true}>Sending</SendButton> : <SendButton onClick={onSubmit}>Send</SendButton>}

      {successMessage && <SuccessMessage>{successMessage}</SuccessMessage>}
      {failMessage && <FailMessage>{failMessage}</FailMessage>}
    </Container>
  )
}

const Container = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-self: stretch;
  @media screen and (min-width: 1200px) {
    margin-left: 120px;
  }
  @media screen and (max-width: 1199px) {
    /* padding-top: 40px; */
    margin-top: -20px;
  }
`

const MobileStub = styled.div`
  height: 60px;
  @media screen and (min-width: 1200px) {
    display: none;
  }
`

const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-self: stretch;
`

const ContactsRow = styled.div`
  display: flex;
  margin-top: 10px;
  & > div {
    flex: 1;
  }
  & > :not(:first-child) {
    margin-left: 30px;
  }
  @media screen and (max-width: 1199px) {
    margin-top: 20px;
    flex-direction: column;
    & > :not(:first-child) {
      margin-left: 0;
      margin-top: 30px;
    }
  }
`

const MessageContainer = styled.div`
  margin-top: 30px;
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  & > div {
    flex: 1;
  }
`

const AttachButton = styled.img`
  position: absolute;
  right: 0;
  top: 24px;
  width: 24px;
  cursor: pointer;
  user-select: none;
  transition: 0.3s all;

  @media screen and (max-width: 1199px) {
    top: 20px;
  }

  @media (max-width: 420px) {
    top: 10px;
  }

  &:hover {
    opacity: 0.5;
  }
`

const SendButton = styled(BigWhiteButton)`
  margin-top: 60px;
  align-self: flex-start;

  @media (max-height: 730px) {
    margin-top: 30px;
  }

  @media screen and (max-width: 1199px) {
    margin-top: 40px;
    align-self: center;
  }
`

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const Error = styled.div`
  color: red;
  height: 0;
  opacity: 0;
  transition: all 0.15s ease-in-out;
  padding-top: 6px;
  &.active {
    height: 20px;
    opacity: 1;
  }
`

const FinishMessage = styled.div`
  font-size: 17px;
  line-height: 25px;
  margin-top: 30px;
  text-align: center;
`

const SuccessMessage = styled(FinishMessage)`
  color: #4c0;
`

const FailMessage = styled(FinishMessage)`
  color: red;
`

const InvisibleFileInput = styled.input`
  display: none;
`

const FileName = styled.div`
  margin-top: 10px;
  color: #ffffff;
  opacity: 0.7;
`
