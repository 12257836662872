import styled from "astroturf"
import React, { useEffect, useState } from "react"
import { SubHeader } from "./SubHeader"
import { HeaderLink } from "./HeaderLink"
import YMInitializer from "react-yandex-metrika/lib/init"
import ym from "react-yandex-metrika"
import { getCookie } from "../../constants/getCookie"

if (typeof window !== `undefined`) {
  YMInitializer([44277909], { webvisor: true }, "2")
}

export const Header = ({ activeLink, stickyHeader = false, goTo }) => {
  const [scrolled, setScrolled] = useState(false)

  const [active, setActive] = useState(false)
  const onClickHamburger = () => {
    setActive(active => !active)
  }

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 10
      if (isScrolled !== scrolled) {
        setScrolled(!scrolled)
      }
    }

    document.addEventListener("scroll", handleScroll, { passive: true })

    return () => {
      // clean up the event handler when the component unmounts
      document.removeEventListener("scroll", handleScroll)
    }
  }, [scrolled])

  useEffect(() => {
    if (!getCookie("URL")) {
      if (!document.referrer.includes(window.location.host) && document.referrer) {
        document.cookie = `URL=${document.referrer}; path=/;`
      }
    }
    if (!getCookie("UTM")) {
      if (window.location.href.toLowerCase().includes("utm")) {
        document.cookie = `UTM=${window.location.href}; path=/;`
      }
    }
    ym("getClientID", function (clientID) {
      document.cookie = `clientID=${clientID}; path=/;`
    })
    document.cookie = "firstVisit=false;path=/;"
  }, [])

  return (
    <div className="header_hor l-body state_defaul no-touch">
      <HeaderContainer sticky={stickyHeader || scrolled}>
        <SubHeader sticky={stickyHeader || scrolled} />
        <HeaderLink
          sticky={stickyHeader || scrolled}
          goTo={goTo}
          activeLink={activeLink}
          active={active}
          onClickHamburger={onClickHamburger}
        />
      </HeaderContainer>
    </div>
  )
}

const HeaderContainer = styled.header`
  &.sticky {
    height: 50px;
  }
  width: 100%;
  position: fixed;
  z-index: 111;
  background: linear-gradient(rgba(0, 0, 0, 0.5), transparent) repeat-x;
  transition-property: transform, background-color, box-shadow, line-height, height;
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.78, 0.13, 0.15, 0.86);
`
