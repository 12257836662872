import styled from "astroturf"

export const BigWhiteButton = styled.a`
  cursor: pointer;
  height: 60px;
  background: #ffffff;
  border-radius: 30px;
  color: #102037;
  padding: 0 60px;
  margin-top: 50px;
  align-items: center;
  display: flex;
  white-space: nowrap;
  user-select: none;
  text-decoration: none;
  transition: all 0.15s ease-in-out;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  &:hover {
    background-color: #dee3eb;
  }
  @media screen and (max-width: 1199px) {
    height: 50px;
    margin-top: 40px;
  }
`

export const TransparentButton = styled.a`
  display: flex;
  align-items: center;
  height: 40px;
  border: 1px solid #ffffff;
  color: #ffffff;
  box-sizing: border-box;
  border-radius: 20px;
  padding: 0 30px;
  cursor: pointer;
  white-space: nowrap;
  text-decoration: none;
  user-select: none;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  transition: all 0.15s ease-in-out;
  &:hover {
    background-color: #ffffff;
    color: #102037;
  }
`

export const OldWhiteButton = styled.a`
  font-size: 16px;
  box-sizing: border-box;
  border: 1px solid #ffffff;
  cursor: pointer;
  min-height: 38px;
  background: #ffffff;
  border-radius: 30px;
  color: #102037;
  padding: 7px 27px;
  align-items: center;
  white-space: nowrap;
  user-select: none;
  text-decoration: none;
  transition: all 0.15s ease-in-out;
  font-weight: 600;
  align-self: flex-start;
  &:hover {
    background-color: transparent;
    color: white;
  }
`
