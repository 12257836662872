import styled from "astroturf"
import React from "react"
import ym from "react-yandex-metrika"

export const HeaderLink = ({ sticky, desktop = true, onClickHamburger, closeMenu, active, activeLink = "", goTo }) => {
  const onClickSubmitApplication = () => {
    ym("reachGoal", "clickSubmitApp")
    if (goTo) {
      goTo(4)
    } else {
      const writeToUs = document.getElementById("writeToUs")
      if (writeToUs) {
        writeToUs.scrollIntoView({ behavior: "smooth", block: "start" })
      }
    }
  }
  const scrollOnClick = id => {
    const scroll = document.getElementById(id)
    if (scroll) {
      scroll.scrollIntoView({ behavior: "smooth", block: "start" })
    }
  }

  return (
    <Container sticky={sticky} active={active}>
      <ContentContainer className="container">
        <DexLogo sticky={sticky} href={"/"} aria-label={"Dex"}>
          <svg
            id="svg-logo"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 795.378 331.585"
            width="109px"
            height="60px"
          >
            <g id="dexlogowhite" transform="translate(-103)">
              <path
                fill="#fff"
                d="M284.014,114.955V0h70.07V325.36h-70.07V303.365c-16.266,19.09-39.206,28.22-69.653,28.22-30.864,0-57.14-11.62-78.829-35.275S103,243.6,103,208.745q0-51.667,32.532-87.15c22.106-24.07,48.382-35.69,78.829-35.69C244.391,86.32,267.748,95.865,284.014,114.955ZM172.653,209.16c0,17.43,5.005,31.125,15.432,41.5s23.774,15.355,40.04,15.355,30.03-4.98,40.04-15.355c10.427-10.375,15.432-24.07,15.432-41.5s-5-31.125-15.432-41.5S244.391,152.3,228.125,152.3s-30.03,4.98-40.04,15.355C177.658,178.035,172.653,191.73,172.653,209.16Z"
              />
              <path
                fill="#fff"
                d="M633.947,236.965h-166c7.925,21.58,26.277,32.37,55.889,32.37,19.186,0,33.784-5.81,45.045-17.845l55.89,31.955C601.832,315.4,568.048,331.17,523,331.17c-38.788,0-70.487-11.62-94.26-34.86-23.357-23.24-35.035-52.705-35.035-87.98,0-34.86,11.678-63.91,34.617-87.565C451.682,97.11,481.712,85.49,518,85.49c33.784,0,62.146,11.62,84.668,35.275,22.94,22.825,34.2,51.875,34.2,87.565A144.2,144.2,0,0,1,633.947,236.965ZM466.7,185.09H567.214c-6.673-24.485-23.357-36.935-49.633-36.935C490.471,148.155,473.37,160.6,466.7,185.09Z"
              />
              <path fill="#fc336f" d="M774.5,154.38H855l43.377-61.42h-80.5Z" />
              <path
                fill="#fff"
                d="M895.458,325.36h-80.5L774.5,267.675,734.047,325.36h-80.5l80.915-114.125L650.63,92.96h80.5L774.5,154.38l40.04,56.855Z"
              />
            </g>
          </svg>
        </DexLogo>
        <RightContainer>
          <LinkContainer hide={!active}>
            <Link onClick={() => scrollOnClick("home")}>Main</Link>
            {/* <Link href="/Services/" active={activeLink === "Services"}>Услуги</Link> */}
            <Link onClick={() => scrollOnClick("cases")}>Cases</Link>
            <Link onClick={() => scrollOnClick("activity")}>Events</Link>
            {/* <Link href="https://Job.dex-it.ru" target="_blank">
              Вакансии
            </Link> */}
            {/* <Link mobile onClick={onClickHamburger}>
              Contacts
            </Link> */}
          </LinkContainer>
          <span onClick={onClickHamburger}>
            <IconBurger animation={active} />
          </span>
          <WhiteButton onClick={() => onClickSubmitApplication("writeToUs")}>Submit request</WhiteButton>
        </RightContainer>
      </ContentContainer>
    </Container>
  )
}

const Container = styled.div`
  height: 100px;
  transition-timing-function: cubic-bezier(0.78, 0.13, 0.15, 0.86);
  transition-property: transform, background-color, box-shadow, line-height, height;
  transition-duration: 0.3s;

  &.sticky {
    height: 50px;
    overflow: hidden;
    background-color: #252f3c;
  }

  &.active {
    background-color: #252f3c;
  }

  @media (max-width: 767px) {
    height: 50px;
  }
`

const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;

  @media (max-width: 767px) {
    padding: 0 15px;
  }
`

const DexLogo = styled.a`
  line-height: 0;
  svg {
    transition: height 0.5s;
  }
  &.sticky {
    svg {
      height: 32px;
    }
  }

  @media (max-width: 767px) {
    svg {
      height: 32px;
    }
  }
`

const RightContainer = styled.div`
  display: flex;
`

const IconBurger = styled.div`
  display: none;
  @media (max-width: 767px) {
    display: block;
    position: relative;
    margin: 10px 20px 10px 0;
    height: 3px;
    background: #fff;
    width: 20px;

    &:after {
      height: 3px;
      background: #fff;
      width: 20px;
      content: " ";
      position: absolute;
      top: -7px;
    }
    &:before {
      height: 3px;
      background: #fff;
      width: 20px;
      content: " ";
      position: absolute;
      top: 7px;
    }

    &.animation {
      animation: centerLine 0.1s ease;
      animation-fill-mode: forwards;

      &:after {
        animation: leftLine 0.3s ease;
        animation-fill-mode: forwards;
      }
      &:before {
        animation: rightLine 0.3s ease;
        animation-fill-mode: forwards;
      }

      @keyframes rightLine {
        30% {
          top: 0;
        }

        40% {
          top: 0;
          transform: rotate(0deg);
        }

        100% {
          top: 0;
          transform: rotate(45deg);
        }
      }

      @keyframes leftLine {
        30% {
          top: 0;
        }

        40% {
          top: 0;
          transform: rotate(0deg);
        }

        100% {
          top: 0;
          transform: rotate(135deg);
        }
      }

      @keyframes centerLine {
        100% {
          height: 0;
        }
      }

      @keyframes centerLine {
        100% {
          height: 0;
        }
      }
    }
  }
`

const WhiteButton = styled.a`
  background-color: #ffffff;
  color: #212121;
  border-radius: 28px;
  transition: 0.3s;
  transition-timing-function: cubic-bezier(0.78, 0.13, 0.15, 0.86);
  border: solid 1px white;

  font-size: 15px;
  font-weight: 500;
  line-height: 2.3;
  padding: 0 1.5em;
  cursor: pointer;

  &:hover {
    background: inherit;
    color: #ffffff;
  }

  @media (max-width: 600px) {
    font-size: 11px;
    padding: 0 0.5rem;
  }
`

const LinkContainer = styled.nav`
  display: flex;
  align-items: center;
  margin-right: 20px;
  @media (max-width: 767px) {
    position: absolute;
    display: flex;
    flex-direction: column;
    left: 0;
    top: 49px;
    z-index: -1;
    margin-right: 0;
    background: #252f3c;
    width: 100%;
    transition: all 0.3s ease-out;

    &.hide {
      top: -500px;
    }
  }
`

const Link = styled.a`
  font-size: 16px;
  padding: 0 20px;
  color: #fff;
  text-transform: uppercase;
  position: relative;
  cursor: pointer;
  &.active {
    color: #fc336f;
  }

  &:before {
    display: block;
    position: absolute;
    content: "";
    height: 1px;
    width: 0;
    background: white;
    transition: width 0.3s ease-in-out, left 0.3s ease-in-out;
    left: 50%;
    bottom: -5px;
  }

  &:after {
    display: block;
    position: absolute;
    content: "";
    height: 1px;
    width: 0;
    background-color: white;
    transition: width 0.3s ease-in-out;
    left: 50%;
    bottom: -5px;
  }
  &:hover {
    &:before {
      width: 50%;
      left: 0;
    }

    &:after {
      width: 50%;
    }
  }

  &.mobile {
    display: none;
    @media (max-width: 767px) {
      display: inline;
    }
  }

  @media (max-width: 767px) {
    padding: 10px 15px;
    width: 100%;
    text-align: center;
  }
`
