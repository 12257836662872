export const messageTypes = {
  mobile: {
    key: "mobile",
    label: "Mobile",
  },
  web: {
    key: "web",
    label: "Web",
  },
  backend: {
    key: "backend",
    label: "Backend",
  },
  design: {
    key: "design",
    label: "Design",
  },
  analitics: {
    key: "analitics",
    label: "Business Analysis",
  },
  test: {
    key: "test",
    label: "Testing",
  },
}
