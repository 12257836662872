import styled from "astroturf"
import React, { useMemo } from "react"

export const ChipSelectGroup = ({ selected, onSelect }) => {
  const options = useMemo(
    () => Object.keys(selected).map(key => selected[key]),
    [selected]
  )

  const onClickChip = key => () => {
    onSelect(key)
  }

  return (
    <Container>
      {options.map(item => (
        <Chip
          key={item.key}
          onClick={onClickChip(item.key)}
          active={item.active}
        >
          {item.label}
        </Chip>
      ))}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  & > :not(:last-child) {
    margin-right: 20px;
  }
`

const Chip = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 30px;
  background: #273242;
  border-radius: 20px;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
  transition: all 0.15s ease-in-out;
  margin-bottom: 20px;
  user-select: none;

  @media (max-height: 730px){
    font-size: 18px;
    margin-bottom: 20px;
  }

  @media screen and (min-width: 1200px) {
    &:hover {
      background: #dee3eb;
      color: #121e30;
    }
  }

  &.active {
    background: #ffffff;
    color: #121e30;
  }

  @media screen and (max-width: 1199px) {
    font-size: 16px;
    line-height: 19px;
  }
`
