export const HOME_PAGE_TITLE = `Solid team of developers to strengthen your product`
export const HOME_PAGE_DESCRIPTION = `We design, develop and support innovative Mobile, Web and Cross-platform IT solutions`

export const ACTIVITY_PAGE_TITLE = `Компания Dex. Мероприятия`
export const ACTIVITY_PAGE_DESCRIPTION = `D-day, iT ЗА!БЕГ, ИТ-сообщество, Мини-футбол`

export const ANALYTICS_PAGE_TITLE = `Компания Dex. Аналитика`
export const ANALYTICS_PAGE_DESCRIPTION = `Каждый проект начинается с аналитики - собираем и анализируем требования, составляем ТЗ и макеты экранов`

export const WebDevelopment_PAGE_TITLE = `Компания Dex. Frontend разработка`
export const WebDevelopment_PAGE_DESCRIPTION = `Создадим адаптивный сайт или web-сервис с богатой функциональностью`

export const Backend_PAGE_TITLE = `Компания Dex. Backend разработка`
export const Backend_PAGE_DESCRIPTION = `Разработаем гибкую, масштабируемую и отказоустойчивую архитектуру`

export const Outsourcing_PAGE_TITLE = ``
export const Outsourcing_PAGE_DESCRIPTION = ``

export const Testing_PAGE_TITLE = `Компания Dex. Тестирование и DevOps`
export const Testing_PAGE_DESCRIPTION = `Составим тест-планы, проведем полное ручное тестирование и при необходимости напишем автоматизированные тесты`

export const Design_PAGE_TITLE = `Компания Dex. UX/UI`
export const Design_PAGE_DESCRIPTION = `Наши юзабилити-эксперты и дизайнеры сделают сложный интерфейс простым, удобным и красивым`

export const Services_PAGE_TITLE = `Компания Dex. Услуги`
export const Services_PAGE_DESCRIPTION = `Аналитика и Дизайн, Backend Разработка, Frontend Разработка, Мобильная Разработка, Тестирование и DevOps, Аутсорс`
